import {Component, Inject, OnInit} from '@angular/core';
import {CampaignItemTargetDetails, CampaignItemService, CampaignItemFilter, JobItemStatus} from 'mamgo-api';
import {ActivatedRoute, Router} from '@angular/router';
import {Errors} from '../helpers/Errors';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';
import {DOCUMENT} from "@angular/common";
import {Title} from '@angular/platform-browser';

@Component({
  animations: [
    trigger('openClose', [
      state('open', style({
        height: '250px',
      })),
      state('closed', style({
        height: '40px',
      })),
      transition('open => closed', [
        animate('0.6s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
    ]),
  ],
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.scss']
})
export class JobListComponent implements OnInit {
  isOpen = false;
  qCity: string;
  qTitle: string;
  qCompany: string;
  page: number;
  total: number;
  hideCity = false;
  hideForm = false;
  filter: CampaignItemFilter = {
    /**
     * to list items intended for facebook apps, use 'facebook' here
     * perhaps analyse referrer or use an argument in query string
     */
    //brokerName: ['google'],
    // campaignName: ["hays"],
    // city: ["München", "Stuttgart", "Berlin"],
    status: [JobItemStatus.Posted],
    title: '',
    count: 25,
    continue: 0
  };

  jobs: CampaignItemTargetDetails[] = [];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private route: ActivatedRoute,
    private router: Router,
    private title: Title,
    private campaignItemService: CampaignItemService
  ) {
  }

  ngOnInit(): void {
    if(document.referrer.indexOf('google')>-1)
      this.filter.brokerName=['google'];
    else if(document.referrer.indexOf('facebook')>-1)
      this.filter.brokerName=['facebook'];
    else if(document.referrer.indexOf('linkedin')>-1||document.referrer.indexOf('lnkd.in')>-1)
      this.filter.brokerName=['linkedin'];
    else {
      //console.log('Referrer: '+document.referrer);
      const utm_source=this.route.snapshot.queryParamMap.get('utm_source');
      switch(utm_source) {
        case 'google':
        case 'facebook':
        case 'linkedin':
          // only use supported brokers (just in case some weirdo uses custom utm codes)
          this.filter.brokerName=[utm_source];
          break;
      }
    }


    // Url Fragmenten arbeiten
    this.route.url.subscribe(fragments => {
      fragments.forEach((fragment, index) => {
          switch (index){
            case 0:
              if (fragment.path !== 'all') {
                this.filter.companyName = [this.generateFragmentFilter(fragment.path)];
                this.qCompany = fragment.path;
              }
              break;
            case 1:
              this.hideCity = true;
              if (fragment.path !== 'all') {
                this.filter.city = [this.generateFragmentFilter(fragment.path)];
                this.qCity = fragment.path;
              }
              break;
            case 2:
              this.hideForm = true;
              // title search automatically uses wildcards in backend, so no fragment filter detection necessary
              this.filter.title = fragment.path;
              this.qTitle = fragment.path;
              break;
          }
      });
    });

    this.createTitle();

    // QueryParameter arbeiten
    // this.route.queryParams.subscribe((queryParam) => {});

    this.qCity = this.route.snapshot.queryParamMap.get('city');
    if (this.qCity !== null && this.qCity !== '') { this.filter.city = [this.generateFragmentFilter(this.qCity)]; }

    this.qTitle = this.route.snapshot.queryParamMap.get('title');
    if (this.qTitle !== null && this.qTitle !== '') {
      this.filter.title = this.qTitle;
    }

    const companyQuery=this.route.snapshot.queryParamMap.get('company');
    if(companyQuery)
      this.filter.companyName=[this.generateFragmentFilter(companyQuery)];

    // TODO: set filters extracted from query/url here

    this.loadPage();
  }
  capitalize(str: string, lower :boolean = false):string {
    return (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
  }

  createTitle():void{

    let title_value = '';
    if(this.qTitle && this.qCity){
      title_value = this.capitalize(this.qTitle) + ' in ' + this.capitalize(this.qCity);
    }
    else if(this.qTitle || this.qCity){
      if(this.qCity)
        title_value = 'Jobs in '+this.capitalize(this.qCity);
      if(this.qTitle)
        title_value += this.capitalize(this.qTitle);
    }
    if(this.qCompany){
      const pTitle = this.qCompany.replace('-', ' ');
      if(title_value != '') title_value += ' bei ';
      title_value = title_value + this.capitalize(pTitle);
    }
    if(title_value != '') {
      this.title.setTitle(title_value += ' | mamgo.io');
    }else{
      this.title.setTitle('Jobs | mamgo.io');
    }
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }
  generateFragmentFilter(fragment: string): string {
    let filter = '';
    for (let character of fragment.toLowerCase()) {
      if (character >= 'a' && character <= 'z') {
        filter += character;
      }
      else if(character=='~')
        filter+='*';
      else { filter += '?'; }
    }
    filter += '*';
    return filter;
  }

  searchCity(city: any){
    this.filter.city = [city.target.value];
    this.qCity = city.target.value;
    this.setQueryParams({city: city.target.value});
  }

  searchTitle(title: any){
    this.filter.title = title.target.value;
    this.qTitle = title.target.value;
    this.setQueryParams({title: title.target.value});
  }
  
  setQueryParams(queryParams: Object){
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }

  loadPage(reset: boolean = false): void {
    if(typeof this.filter.city !== 'undefined' && this.filter.city[0] === '')
      this.filter.city = ['*'];
    this.campaignItemService.list(this.filter).subscribe(page => {
      if(reset)
        this.filter.continue = 0;
      this.total = page.total;
      this.page = this.filter.continue / this.filter.count;
      this.jobs = page.result;
      // if no result, show form
      if(page.total == 0){
        this.hideForm = false;
        this.hideCity = false;
      }
    }, e => {
      // in case of an error ...
      // don't know whether snackbar makes sense for a job listing
      console.log(Errors.getError(e));
    });
  }

  onPageChanged(page: number): void {
    this.filter.continue = page * this.filter.count;
    // this.filter.count=size;
    this.loadPage(false);
  }

  getTotalPages(): number {
    return Math.ceil(this.total / this.filter.count);
  }
}
