<div id="wrapper-main">
  <div class="container main">
    <img class="logo srow" *ngIf="hideForm" src="./assets/mgo-logo.svg" alt="Mamgo.io">
      <div [ngClass]="{'searchbar_nocity': this.hideCity, 'searchbar': !this.hideCity}" *ngIf="!hideForm" [@openClose]="isOpen ? 'open' : 'closed'">
        <img class="logo srow" src="./assets/mgo-logo.svg" alt="Mamgo.io"><span (click)="toggle()" class="material-icons icn search" >search</span>
        <div class="srow" *ngIf="!hideCity">
          <mat-form-field appearance="standard">
            <mat-label>Stadt</mat-label>
            <input name="city" (keyup.enter)="loadPage(true)" (change)="searchCity($event)" [value]="qCity" matInput placeholder="Stadt..">
            <mat-hint>Stadt oder Ortsnamen eingeben</mat-hint>
          </mat-form-field>
        </div>
        <div class="srow">
          <mat-form-field appearance="standard">
            <mat-label>Titel</mat-label>
            <input name="title" [value]="qTitle" (keyup.enter)="loadPage(true)" (change)="searchTitle($event)" matInput placeholder="Titel des Jobs..">
            <mat-hint>Nach Titel suchen..</mat-hint>
          </mat-form-field>
        </div>
        <div class="srow last">
          <button mat-flat-button (click)="loadPage(true)" color="primary"><span class="extra">Suchen</span></button>
        </div>
      </div>
    <br>
      <div class="jobcard-container"><jobcard *ngFor="let job of jobs" [job]="job"></jobcard></div>
      <div *ngIf="getTotalPages() > 1; else nothingfound" class="paging">
        <button mat-mini-fab color="primary" [disabled]="page<=0" (click)="onPageChanged(page-1)"><span class="material-icons icn">navigate_before</span></button>
        <span class="pageinfo">Seite {{page+1}} von {{getTotalPages()}}</span>
        <button mat-mini-fab color="primary" [disabled]="(page+1)*filter.count>=total" (click)="onPageChanged(page+1)"><span class="material-icons icn">navigate_next</span></button>
      </div>
  </div>

  <ng-template #nothingfound>
    <mat-card *ngIf="getTotalPages() == 0">
      <mat-card-header>
        <mat-card-title>Keine Jobs gefunden, versuch es nochmal mit anderen Suchkriterien.</mat-card-title>
      </mat-card-header>
    </mat-card>
  </ng-template>
  <footer>
    <div class="copyright_footer">
      <div class="rights_text">mamgo GmbH &amp; Co. KG © 2022 </div>
      <div class="terms">
        <a href="//mamgo.io/datenschutz" class="terms_link">Datenschutz</a><a href="//mamgo.io/impressum" class="terms_link imoressu">Impressum</a>
      </div>
    </div>
  </footer>
</div>
