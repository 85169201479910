import {Component, Inject, Input, OnInit} from '@angular/core';
import {CampaignItemTargetDetails,CompanyService, TargetService, JobAdService, ClickType} from "mamgo-api";
import {ActivatedRoute} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Errors} from "../helpers/Errors";
import {DOCUMENT, NumberFormatStyle} from "@angular/common";
import {DomSanitizer, SafeUrl, Title} from '@angular/platform-browser';
import {ImageCacheService} from "../../../../mamgo-api/src/lib/services/image-cache.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Component({
  selector: 'jobcard',
  templateUrl: './jobcard.component.html',
  styleUrls: ['./jobcard.component.scss']
})
export class JobcardComponent implements OnInit {
  @Input() job: CampaignItemTargetDetails;
  companyImageUrl: SafeUrl = '';
  jobType : String = '';
  salary : string = '';
  pattern = /(?<minSalary>\d+)(-(?<maxSalary>\d+))?/;
  salaryOptions = {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  };

  days = '';

  constructor(@Inject(DOCUMENT) private document: Document,
              private companyService: CompanyService,
              private imageCache: ImageCacheService) {

  }

  ngOnInit(): void {
    this.days = this.daysSincePublish(this.job.date);
    const imageKey: string = `company.iconlogo.${this.job.company.id}`;
    if (this.job.salary) this.salary = this.prepareSalary(this.job.salary);
    this.imageCache.getImage(imageKey,
      (url)=> this.companyImageUrl = url,
      () => this.companyService.getCompanyImage(this.job.company.id,{type:0}));
  }

  daysSincePublish(date: Date): string {
    if (!date)
      return '';

    try{
      const theDate: any = new Date(date);
      const now: any = new Date();
      const days = Math.ceil((now - theDate) / (1000 * 60 * 60 * 24));
      if (days < 8) return 'Neu';
      if (days < 30) return 'Erschienen vor ' + days+ ' Tagen';
      return 'Erschienen vor 30+ Tagen';
    } catch (e) {
      return '';
    }
  }

  private static guessAmount(amount: number): number {
    if (amount < 10)
      return 0;

    if (amount < 200)
      return amount * 160 * 12;
    if (amount < 20000)
      return amount * 12;
    return amount;
  }

  prepareSalary(salary:string): string {
    const match = this.pattern.exec(salary);
    if (match.groups.minSalary === undefined)
      return undefined;

    const minAmount = JobcardComponent.guessAmount(Number(match.groups.minSalary));
    if (minAmount === 0)
      return undefined;

    if (match.groups.maxSalary === undefined)
      return new Intl.NumberFormat('de-DE', this.salaryOptions).format(minAmount) + " p.a.";

    const maxAmount = JobcardComponent.guessAmount(Number(match.groups.maxSalary));
    if (maxAmount === 0)
      return new Intl.NumberFormat('de-DE', this.salaryOptions).format(minAmount) + " p.a.";

    const minField = new Intl.NumberFormat('de-DE', this.salaryOptions).format(minAmount);
    const maxField = new Intl.NumberFormat('de-DE', this.salaryOptions).format(maxAmount);
    return `${minField} - ${maxField} p.a.`;
  }

}
