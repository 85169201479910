<a [href]="job.url" class="job-item-link">
  <div class="job-item">
    <ng-container *ngIf="companyImageUrl; else emptySrc">
      <img [src]="companyImageUrl" [alt]="job.company.name" loading="lazy">
    </ng-container>
     <div class="job-info">
       <div class="job-info-wrapper">
         <h2>{{job.title}}</h2>
         <p>{{job.company.name}} | {{job.location.city}} | {{job.location.country}}</p>
         <div class="subinfo-container">
         <span *ngIf="days !=''" class="subinfo"><span class="material-icons icn">calendar_today</span>&nbsp; {{days}}</span>
           <span *ngIf="jobType != ''" class="subinfo"><span class="material-icons icn">schedule</span>&nbsp;{{jobType}}</span>
           <span *ngIf="this.salary" class="subinfo"><span class="material-icons icn cc">credit_card</span>&nbsp;{{this.salary}}</span>
         </div>
       </div>
     </div>
  </div>
</a>
<ng-template #emptySrc>
  <div class="placeholder"></div>
</ng-template>
